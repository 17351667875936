// import React, { useState, useEffect } from "react";
// import Swal from "sweetalert2";
// import { Form, Button } from "react-bootstrap";
// import Sidebar from "./Sidebar";
// import DashNavbar from "./DashNavbar";
// import Select from "react-select";

// export default function EvaluationForm() {
//   const [formData, setFormData] = useState({
//     candidate: "",
//     candidateEmail: "",
//     educationalBackground: "",
//     educationalBackgroundComments: "",
//     priorWorkExperience: "",
//     priorWorkExperienceComments: "",
//     technicalQualifications: "",
//     technicalQualificationsComments: "",
//     verbalCommunication: "",
//     verbalCommunicationComments: "",
//     candidateInterest: "",
//     candidateInterestComments: "",
//     knowledgeOfOrganization: "",
//     knowledgeOfOrganizationComments: "",
//     teambuildingInterpersonalSkills: "",
//     teambuildingInterpersonalSkillsComments: "",
//     initiative: "",
//     initiativeComments: "",
//     timeManagement: "",
//     timeManagementComments: "",
//     overallImpression: "",
//     overallImpressionComments: "",
//   });
//   const [candidates, setCandidates] = useState([]);

//   useEffect(() => {
//     fetchCandidates();
//   }, []);

//   const fetchCandidates = async () => {
//     try {
//       const email = localStorage.getItem("email");
//       const response = await fetch("https://raasbackend.vetrinahealthcare.com/interviewers/", {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify({ email: email }),
//       });
//       if (!response.ok) {
//         throw new Error("Failed to fetch candidates");
//       }
//       const data = await response.json();

//       const candidatesData = data.map((candidate) => ({
//         name: candidate.candidate,
//         email: candidate.candidate_email,
//       }));
//       setCandidates(candidatesData);
//     } catch (error) {
//       console.error("Error fetching candidates:", error);
//     }
//   };

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({
//       ...formData,
//       [name]: value,
//     });
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     try {
//       const formDataWithCandidateEmail = {
//         ...formData,
//         candidateEmail: candidates.find(
//           (candidate) => candidate.name === formData.candidate
//         )?.email,
//       };

//       const response = await fetch("https://raasbackend.vetrinahealthcare.com/save_evaluation/", {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify(formDataWithCandidateEmail),
//       });

//       if (!response.ok) {
//         throw new Error("Failed to save evaluation");
//       }

//       Swal.fire({
//         icon: "success",
//         title: "Great",
//         text: "Candidate evaluated successfully!",
//         timer: 1000,
//         timerProgressBar: true,
//         showConfirmButton: false,
//       });
//     } catch (error) {
//       console.error("Error saving evaluation:", error);

//       Swal.fire({
//         icon: "warning",
//         title: "Oops...",
//         text: "You have already evaluated this candidate!",
//         timer: 4000,
//         timerProgressBar: true,
//         showConfirmButton: false,
//       });
//     }
//   };

//   return (
//     <>
//       <DashNavbar />
//       <div className="wrapper">
//         <Sidebar />
//         <div className="main">
//           <div className="container mt-2">
//             <div
//               className="row justify-content-center "
//               style={{ width: "100%", textAlign: "start" }}
//             >
//               <div className="col-md-8 col-9 auth-inner w-75">
//                 <h2 className="display-6 gradient-text mb-4 text-center">
//                   Evaluation Form
//                 </h2>
//                 <p className="text-success">
//                   Interview evaluation forms are to be completed by the
//                   interviewer to rank the candidate’s overall qualifications for
//                   the position for which they have applied. Under each heading,
//                   the interviewer should give the candidate a rating and write
//                   specific job-related comments in the space provided.
//                 </p>
//                 <Form onSubmit={handleSubmit}>
//                   <Form.Group controlId="candidate">
//                     <Select
//                       options={candidates.map((candidate) => ({
//                         value: candidate.name,
//                         label: candidate.name,
//                       }))}
//                       value={
//                         formData.candidate
//                           ? {
//                               value: formData.candidate,
//                               label: formData.candidate,
//                             }
//                           : null
//                       }
//                       onChange={(selectedOption) =>
//                         setFormData({
//                           ...formData,
//                           candidate: selectedOption?.value || "",
//                         })
//                       }
//                       placeholder="Select Candidate"
//                       isSearchable={true}
//                       styles={{
//                         control: (styles) => ({
//                           ...styles,
//                           textAlign: "start",
//                         }),
//                       }}
//                     />
//                   </Form.Group>

//                   <hr />
//                   <div className="row">
//                     <div className="col-md-6">
//                       <Form.Group controlId="educationalBackground">
//                         <Form.Label className="mb-2 text-start">
//                           1. Educational Background
//                         </Form.Label>
//                         <Form.Control
//                           as="select"
//                           name="educationalBackground"
//                           value={formData.educationalBackground}
//                           onChange={handleChange}
//                           className="form-select"
//                         >
//                           <option value="">Select rating...</option>
//                           <option
//                             value="5"
//                             dropdown-toggle
//                             dropdown-toggle-split
//                           >
//                             Exceptional
//                           </option>
//                           <option value="4">Above Average</option>
//                           <option value="3">Average</option>
//                           <option value="2">Satisfactory</option>
//                           <option value="1">Unsatisfactory</option>
//                         </Form.Control>
//                         <Form.Control
//                           as="textarea"
//                           rows={2}
//                           name="educationalBackgroundComments"
//                           value={formData.educationalBackgroundComments}
//                           onChange={handleChange}
//                           placeholder="Comments..."
//                         />
//                       </Form.Group>
//                     </div>
//                     <div className="col-md-6">
//                       <Form.Group controlId="priorWorkExperience">
//                         <Form.Label className="mb-2 text-start">
//                           2. Prior Work Experience
//                         </Form.Label>
//                         <Form.Control
//                           as="select"
//                           name="priorWorkExperience"
//                           value={formData.priorWorkExperience}
//                           onChange={handleChange}
//                           className="form-select"
//                         >
//                           <option value="">Select rating...</option>
//                           <option value="5">Exceptional</option>
//                           <option value="4">Above Average</option>
//                           <option value="3">Average</option>
//                           <option value="2">Satisfactory</option>
//                           <option value="1">Unsatisfactory</option>
//                         </Form.Control>
//                         <Form.Control
//                           as="textarea"
//                           rows={2}
//                           name="priorWorkExperienceComments"
//                           value={formData.priorWorkExperienceComments}
//                           onChange={handleChange}
//                           placeholder="Comments..."
//                         />
//                       </Form.Group>
//                     </div>
//                   </div>
//                   <div className="row">
//                     <div className="col-md-6">
//                       <Form.Group
//                         controlId="technicalQualifications"
//                         className="mt-4"
//                       >
//                         <Form.Label>
//                           3. Technical Qualifications/Experience
//                         </Form.Label>
//                         <Form.Control
//                           as="select"
//                           name="technicalQualifications"
//                           value={formData.technicalQualifications}
//                           onChange={handleChange}
//                         >
//                           <option value="">Select rating...</option>
//                           <option value="5">Exceptional</option>
//                           <option value="4">Above Average</option>
//                           <option value="3">Average</option>
//                           <option value="2">Satisfactory</option>
//                           <option value="1">Unsatisfactory</option>
//                         </Form.Control>
//                         <Form.Control
//                           as="textarea"
//                           rows={2}
//                           name="technicalQualificationsComments"
//                           value={formData.technicalQualificationsComments}
//                           onChange={handleChange}
//                           placeholder="Comments..."
//                         />
//                       </Form.Group>
//                     </div>
//                     <div className="col-md-6">
//                       <Form.Group
//                         controlId="verbalCommunication"
//                         className="mt-4"
//                       >
//                         <Form.Label>4. Verbal Communication</Form.Label>
//                         <Form.Control
//                           as="select"
//                           name="verbalCommunication"
//                           value={formData.verbalCommunication}
//                           onChange={handleChange}
//                         >
//                           <option value="">Select rating...</option>
//                           <option value="5">Exceptional</option>
//                           <option value="4">Above Average</option>
//                           <option value="3">Average</option>
//                           <option value="2">Satisfactory</option>
//                           <option value="1">Unsatisfactory</option>
//                         </Form.Control>
//                         <Form.Control
//                           as="textarea"
//                           rows={2}
//                           name="verbalCommunicationComments"
//                           value={formData.verbalCommunicationComments}
//                           onChange={handleChange}
//                           placeholder="Comments..."
//                         />
//                       </Form.Group>
//                     </div>
//                   </div>
//                   <div className="row">
//                     <div className="col-md-6">
//                       <Form.Group
//                         controlId="candidateInterest"
//                         className="mt-4"
//                       >
//                         <Form.Label>5. Candidate Interest</Form.Label>
//                         <Form.Control
//                           as="select"
//                           name="candidateInterest"
//                           value={formData.candidateInterest}
//                           onChange={handleChange}
//                         >
//                           <option value="">Select rating...</option>
//                           <option value="5">Exceptional</option>
//                           <option value="4">Above Average</option>
//                           <option value="3">Average</option>
//                           <option value="2">Satisfactory</option>
//                           <option value="1">Unsatisfactory</option>
//                         </Form.Control>
//                         <Form.Control
//                           as="textarea"
//                           rows={2}
//                           name="candidateInterestComments"
//                           value={formData.candidateInterestComments}
//                           onChange={handleChange}
//                           placeholder="Comments..."
//                         />
//                       </Form.Group>
//                     </div>
//                     <div className="col-md-6">
//                       <Form.Group
//                         controlId="knowledgeOfOrganization"
//                         className="mt-4"
//                       >
//                         <Form.Label>6. Knowledge of Organization</Form.Label>
//                         <Form.Control
//                           as="select"
//                           name="knowledgeOfOrganization"
//                           value={formData.knowledgeOfOrganization}
//                           onChange={handleChange}
//                         >
//                           <option value="">Select rating...</option>
//                           <option value="5">Exceptional</option>
//                           <option value="4">Above Average</option>
//                           <option value="3">Average</option>
//                           <option value="2">Satisfactory</option>
//                           <option value="1">Unsatisfactory</option>
//                         </Form.Control>
//                         <Form.Control
//                           as="textarea"
//                           rows={2}
//                           name="knowledgeOfOrganizationComments"
//                           value={formData.knowledgeOfOrganizationComments}
//                           onChange={handleChange}
//                           placeholder="Comments..."
//                         />
//                       </Form.Group>
//                     </div>
//                   </div>
//                   <div className="row">
//                     <div className="col-md-6">
//                       <Form.Group
//                         controlId="teambuildingInterpersonalSkills"
//                         className="mt-4"
//                       >
//                         <Form.Label>
//                           7. Teambuilding/Interpersonal Skills
//                         </Form.Label>
//                         <Form.Control
//                           as="select"
//                           name="teambuildingInterpersonalSkills"
//                           value={formData.teambuildingInterpersonalSkills}
//                           onChange={handleChange}
//                         >
//                           <option value="">Select rating...</option>
//                           <option value="5">Exceptional</option>
//                           <option value="4">Above Average</option>
//                           <option value="3">Average</option>
//                           <option value="2">Satisfactory</option>
//                           <option value="1">Unsatisfactory</option>
//                         </Form.Control>
//                         <Form.Control
//                           as="textarea"
//                           rows={2}
//                           name="teambuildingInterpersonalSkillsComments"
//                           value={
//                             formData.teambuildingInterpersonalSkillsComments
//                           }
//                           onChange={handleChange}
//                           placeholder="Comments..."
//                         />
//                       </Form.Group>
//                     </div>
//                     <div className="col-md-6">
//                       <Form.Group controlId="initiative" className="mt-4">
//                         <Form.Label>8. Initiative </Form.Label>
//                         <Form.Control
//                           as="select"
//                           name="initiative"
//                           value={formData.initiative}
//                           onChange={handleChange}
//                         >
//                           <option value="">Select rating...</option>
//                           <option value="5">Exceptional</option>
//                           <option value="4">Above Average</option>
//                           <option value="3">Average</option>
//                           <option value="2">Satisfactory</option>
//                           <option value="1">Unsatisfactory</option>
//                         </Form.Control>
//                         <Form.Control
//                           as="textarea"
//                           rows={2}
//                           name="initiativeComments"
//                           value={formData.initiativeComments}
//                           onChange={handleChange}
//                           placeholder="Comments..."
//                         />
//                       </Form.Group>
//                     </div>
//                   </div>
//                   <div className="row">
//                     <div className="col-md-6">
//                       <Form.Group controlId="timeManagement" className="mt-4">
//                         <Form.Label>9. Time Management</Form.Label>
//                         <Form.Control
//                           as="select"
//                           name="timeManagement"
//                           value={formData.timeManagement}
//                           onChange={handleChange}
//                         >
//                           <option value="">Select rating...</option>
//                           <option value="5">Exceptional</option>
//                           <option value="4">Above Average</option>
//                           <option value="3">Average</option>
//                           <option value="2">Satisfactory</option>
//                           <option value="1">Unsatisfactory</option>
//                         </Form.Control>
//                         <Form.Control
//                           as="textarea"
//                           rows={2}
//                           name="timeManagementComments"
//                           value={formData.timeManagementComments}
//                           onChange={handleChange}
//                           placeholder="Comments..."
//                         />
//                       </Form.Group>
//                     </div>
//                     <div className="col-md-6">
//                       <Form.Group
//                         controlId="overallImpression"
//                         className="mt-4"
//                       >
//                         <Form.Label>
//                           10. Overall Impression and Recommendation
//                         </Form.Label>
//                         <Form.Control
//                           as="select"
//                           name="overallImpression"
//                           value={formData.overallImpression}
//                           onChange={handleChange}
//                         >
//                           <option value="">Select rating...</option>
//                           <option value="5">Exceptional</option>
//                           <option value="4">Above Average</option>
//                           <option value="3">Average</option>
//                           <option value="2">Satisfactory</option>
//                           <option value="1">Unsatisfactory</option>
//                         </Form.Control>
//                         <Form.Control
//                           as="textarea"
//                           rows={2}
//                           name="overallImpressionComments"
//                           value={formData.overallImpressionComments}
//                           onChange={handleChange}
//                           placeholder="Comments..."
//                         />
//                       </Form.Group>
//                     </div>
//                   </div>
//                   <div className="text-center">
//                     <Button
//                       variant="primary"
//                       type="submit"
//                       className="mt-4 btn-block"
//                       style={{ textAlign: "center" }}
//                     >
//                       Submit
//                     </Button>
//                   </div>
//                 </Form>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// }
import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import { Form, Button } from 'react-bootstrap';
import Sidebar from './Sidebar';
import DashNavbar from './DashNavbar';
import Select from 'react-select';

const CustomOption = (props) => {
  const { data, innerRef, innerProps } = props;

  // Generate stars based on the value
  const renderStars = (value) => {
    const fullStar = '★'; // Filled star
    const emptyStar = '☆'; // Empty star
    return fullStar.repeat(value) + emptyStar.repeat(5 - value);
  };

  return (
    <div
      ref={innerRef}
      {...innerProps}
      style={{
        display: 'flex',
        justifyContent: 'space-between', // Align label to the left and stars to the right
        alignItems: 'center',
        padding: '5px 10px', // Padding for better spacing
      }}
    >
      <span>{data.label}</span> {/* Label on the left */}
      <span
        style={{
          color: '#FFD700', // Gold color for stars
          fontSize: '18px',
        }}
      >
        {renderStars(data.value)} {/* Render stars based on the value */}
      </span>
    </div>
  );
};

export default function EvaluationForm() {
  const [formData, setFormData] = useState({
    candidate: '',
    candidateEmail: '',
    educationalBackground: '',
    educationalBackgroundComments: '',
    priorWorkExperience: '',
    priorWorkExperienceComments: '',
    technicalQualifications: '',
    technicalQualificationsComments: '',
    verbalCommunication: '',
    verbalCommunicationComments: '',
    candidateInterest: '',
    candidateInterestComments: '',
    knowledgeOfOrganization: '',
    knowledgeOfOrganizationComments: '',
    teambuildingInterpersonalSkills: '',
    teambuildingInterpersonalSkillsComments: '',
    initiative: '',
    initiativeComments: '',
    timeManagement: '',
    timeManagementComments: '',
    overallImpression: '',
    overallImpressionComments: '',
  });

  const [candidates, setCandidates] = useState([]);
  const [selectedRating, setSelectedRating] = useState("");
  console.log("this is the candidate data", candidates);

  useEffect(() => {
    fetchCandidates();
  }, []);

  const fetchCandidates = async () => {
    try {
      const email = localStorage.getItem('email');
      const response = await fetch('https://raasbackend.vetrinahealthcare.com/interviewers/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: email }),
      });
      if (!response.ok) {
        throw new Error('Failed to fetch candidates');
      }
      const data = await response.json();
      console.log('API Response:', data);

      const candidatesData = data.map((candidate) => ({
        name: candidate.candidate,
        email: candidate.candidate_email,
      }));

      setCandidates(candidatesData);
    } catch (error) {
      console.error('Error fetching candidates:', error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formDataWithCandidateEmail = {
        ...formData,
        candidateEmail: candidates.find(
          (candidate) => candidate.name === formData.candidate
        )?.email,
      };

      const response = await fetch('https://raasbackend.vetrinahealthcare.com/save_evaluation/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formDataWithCandidateEmail),
      });

      if (!response.ok) {
        throw new Error('Failed to save evaluation');
      }

      Swal.fire({
        icon: 'success',
        title: 'Great',
        text: 'Candidate evaluated successfully!',
        timer: 1000,
        timerProgressBar: true,
        showConfirmButton: false,
      });
    } catch (error) {
      console.error('Error saving evaluation:', error);

      Swal.fire({
        icon: 'warning',
        title: 'Oops...',
        text: 'You have already evaluated this candidate!',
        timer: 4000,
        timerProgressBar: true,
        showConfirmButton: false,
      });
    }
  };

  const options = [
    { value: 5, label: 'Exceptional' },
    { value: 4, label: 'Above Average' },
    { value: 3, label: 'Average' },
    { value: 2, label: 'Satisfactory' },
    { value: 1, label: 'Unsatisfactory' },
  ];

  return (
    <>
      <DashNavbar />
      <div className='wrapper'>
        <Sidebar />
        <div className='main'>
          <div className='container mt-2'>
            <div
              className='row justify-content-center'
              style={{ width: '100%', textAlign: 'start' }}
            >
              <div className='col-md-8 col-9 auth-inner w-75'>
                <h2 className='display-6 gradient-text mb-4 text-center'>
                  Evaluation Form
                </h2>
                <p className='text-success'>
                  Interview evaluation forms are to be completed by the
                  interviewer to rank the candidate’s overall qualifications for
                  the position for which they have applied. Under each heading,
                  the interviewer should give the candidate a rating and write
                  specific job-related comments in the space provided.
                </p>
                <Form onSubmit={handleSubmit}>
                  <Form.Group controlId='candidate'>
                    <Select
                      options={candidates.map((candidate) => ({
                        value: candidate.name,
                        label: candidate.name,
                      }))}
                      value={
                        formData.candidate
                          ? {
                              value: formData.candidate,
                              label: formData.candidate,
                            }
                          : null
                      }
                      onChange={(selectedOption) =>
                        setFormData({
                          ...formData,
                          candidate: selectedOption?.value || '',
                        })
                      }
                      placeholder='Select Candidate'
                      isSearchable={true}
                      styles={{
                        control: (styles) => ({
                          ...styles,
                          textAlign: 'start',
                        }),
                      }}
                    />
                  </Form.Group>

                  <hr />
                  <div className='row'>
                    <div className='col-md-6'>
                      <Form.Group controlId='educationalBackground'>
                        <Form.Label className='mb-2 text-start'>
                          1. Educational Background
                        </Form.Label>
                        <Select
                          options={options}
                          components={{ Option: CustomOption }} // Use the custom option
                          value={options.find(option => option.value === formData.educationalBackground) || null}
                          onChange={(selectedOption) =>
                            setFormData({
                              ...formData,
                              educationalBackground: selectedOption.value,
                            })
                          }
                          placeholder='Select rating...'
                          isSearchable={false}
                          styles={{
                            control: (styles) => ({
                              ...styles,
                              textAlign: 'start',
                            }),
                          }}
                        />
                        <Form.Control
                          as='textarea'
                          rows={2}
                          name='educationalBackgroundComments'
                          value={formData.educationalBackgroundComments}
                          onChange={handleChange}
                          placeholder='Comments...'
                        />
                      </Form.Group>
                    </div>
                    <div className='col-md-6'>
                      <Form.Group controlId='priorWorkExperience'>
                        <Form.Label className='mb-2 text-start'>
                          2. Prior Work Experience
                        </Form.Label>
                        <Select
                          options={options}
                          components={{ Option: CustomOption }} // Use the custom option
                          value={options.find(option => option.value === formData.priorWorkExperience) || null}
                          onChange={(selectedOption) =>
                            setFormData({
                              ...formData,
                              priorWorkExperience: selectedOption.value,
                            })
                          }
                          placeholder='Select rating...'
                          isSearchable={false}
                          styles={{
                            control: (styles) => ({
                              ...styles,
                              textAlign: 'start',
                            }),
                          }}
                        />
                        <Form.Control
                          as='textarea'
                          rows={2}
                          name='priorWorkExperienceComments'
                          value={formData.priorWorkExperienceComments}
                          onChange={handleChange}
                          placeholder='Comments...'
                        />
                      </Form.Group>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-md-6'>
                      <Form.Group
                        controlId='technicalQualifications'
                        className='mt-4'
                      >
                        <Form.Label>
                          3. Technical Qualifications/Experience
                        </Form.Label>
                        <Select
                          options={options}
                          components={{ Option: CustomOption }} // Use the custom option
                          value={options.find(option => option.value === formData.technicalQualifications) || null}
                          onChange={(selectedOption) =>
                            setFormData({
                              ...formData,
                              technicalQualifications: selectedOption.value,
                            })
                          }
                          placeholder='Select rating...'
                          isSearchable={false}
                          styles={{
                            control: (styles) => ({
                              ...styles,
                              textAlign: 'start',
                            }),
                          }}
                        />
                        <Form.Control
                          as='textarea'
                          rows={2}
                          name='technicalQualificationsComments'
                          value={formData.technicalQualificationsComments}
                          onChange={handleChange}
                          placeholder='Comments...'
                        />
                      </Form.Group>
                    </div>
                    <div className='col-md-6'>
                      <Form.Group
                        controlId='verbalCommunication'
                        className='mt-4'
                      >
                        <Form.Label>
                          4. Verbal Communication Skills
                        </Form.Label>
                        <Select
                          options={options}
                          components={{ Option: CustomOption }} // Use the custom option
                          value={options.find(option => option.value === formData.verbalCommunication) || null}
                          onChange={(selectedOption) =>
                            setFormData({
                              ...formData,
                              verbalCommunication: selectedOption.value,
                            })
                          }
                          placeholder='Select rating...'
                          isSearchable={false}
                          styles={{
                            control: (styles) => ({
                              ...styles,
                              textAlign: 'start',
                            }),
                          }}
                        />
                        <Form.Control
                          as='textarea'
                          rows={2}
                          name='verbalCommunicationComments'
                          value={formData.verbalCommunicationComments}
                          onChange={handleChange}
                          placeholder='Comments...'
                        />
                      </Form.Group>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-md-6'>
                      <Form.Group controlId='candidateInterest'>
                        <Form.Label className='mt-4'>
                          5. Interest in Position
                        </Form.Label>
                        <Select
                          options={options}
                          components={{ Option: CustomOption }} // Use the custom option
                          value={options.find(option => option.value === formData.candidateInterest) || null}
                          onChange={(selectedOption) =>
                            setFormData({
                              ...formData,
                              candidateInterest: selectedOption.value,
                            })
                          }
                          placeholder='Select rating...'
                          isSearchable={false}
                          styles={{
                            control: (styles) => ({
                              ...styles,
                              textAlign: 'start',
                            }),
                          }}
                        />
                        <Form.Control
                          as='textarea'
                          rows={2}
                          name='candidateInterestComments'
                          value={formData.candidateInterestComments}
                          onChange={handleChange}
                          placeholder='Comments...'
                        />
                      </Form.Group>
                    </div>
                    <div className='col-md-6'>
                      <Form.Group controlId='knowledgeOfOrganization'>
                        <Form.Label className='mt-4'>
                          6. Knowledge of the Organization
                        </Form.Label>
                        <Select
                          options={options}
                          components={{ Option: CustomOption }} // Use the custom option
                          value={options.find(option => option.value === formData.knowledgeOfOrganization) || null}
                          onChange={(selectedOption) =>
                            setFormData({
                              ...formData,
                              knowledgeOfOrganization: selectedOption.value,
                            })
                          }
                          placeholder='Select rating...'
                          isSearchable={false}
                          styles={{
                            control: (styles) => ({
                              ...styles,
                              textAlign: 'start',
                            }),
                          }}
                        />
                        <Form.Control
                          as='textarea'
                          rows={2}
                          name='knowledgeOfOrganizationComments'
                          value={formData.knowledgeOfOrganizationComments}
                          onChange={handleChange}
                          placeholder='Comments...'
                        />
                      </Form.Group>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-md-6'>
                      <Form.Group controlId='teambuildingInterpersonalSkills'>
                        <Form.Label className='mt-4'>
                          7. Team-building/Interpersonal Skills
                        </Form.Label>
                        <Select
                          options={options}
                          components={{ Option: CustomOption }} // Use the custom option
                          value={options.find(option => option.value === formData.teambuildingInterpersonalSkills) || null}
                          onChange={(selectedOption) =>
                            setFormData({
                              ...formData,
                              teambuildingInterpersonalSkills: selectedOption.value,
                            })
                          }
                          placeholder='Select rating...'
                          isSearchable={false}
                          styles={{
                            control: (styles) => ({
                              ...styles,
                              textAlign: 'start',
                            }),
                          }}
                        />
                        <Form.Control
                          as='textarea'
                          rows={2}
                          name='teambuildingInterpersonalSkillsComments'
                          value={formData.teambuildingInterpersonalSkillsComments}
                          onChange={handleChange}
                          placeholder='Comments...'
                        />
                      </Form.Group>
                    </div>
                    <div className='col-md-6'>
                      <Form.Group controlId='initiative'>
                        <Form.Label className='mt-4'>8. Initiative</Form.Label>
                        <Select
                          options={options}
                          components={{ Option: CustomOption }} // Use the custom option
                          value={options.find(option => option.value === formData.initiative) || null}
                          onChange={(selectedOption) =>
                            setFormData({
                              ...formData,
                              initiative: selectedOption.value,
                            })
                          }
                          placeholder='Select rating...'
                          isSearchable={false}
                          styles={{
                            control: (styles) => ({
                              ...styles,
                              textAlign: 'start',
                            }),
                          }}
                        />
                        <Form.Control
                          as='textarea'
                          rows={2}
                          name='initiativeComments'
                          value={formData.initiativeComments}
                          onChange={handleChange}
                          placeholder='Comments...'
                        />
                      </Form.Group>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-md-6'>
                      <Form.Group controlId='timeManagement'>
                        <Form.Label className='mt-4'>9. Time Management</Form.Label>
                        <Select
                          options={options}
                          components={{ Option: CustomOption }} // Use the custom option
                          value={options.find(option => option.value === formData.timeManagement) || null}
                          onChange={(selectedOption) =>
                            setFormData({
                              ...formData,
                              timeManagement: selectedOption.value,
                            })
                          }
                          placeholder='Select rating...'
                          isSearchable={false}
                          styles={{
                            control: (styles) => ({
                              ...styles,
                              textAlign: 'start',
                            }),
                          }}
                        />
                        <Form.Control
                          as='textarea'
                          rows={2}
                          name='timeManagementComments'
                          value={formData.timeManagementComments}
                          onChange={handleChange}
                          placeholder='Comments...'
                        />
                      </Form.Group>
                    </div>
                    <div className='col-md-6'>
                      <Form.Group controlId='overallImpression'>
                        <Form.Label className='mt-4'>10. Overall Impression</Form.Label>
                        <Select
                          options={options}
                          components={{ Option: CustomOption }} // Use the custom option
                          value={options.find(option => option.value === formData.overallImpression) || null}
                          onChange={(selectedOption) =>
                            setFormData({
                              ...formData,
                              overallImpression: selectedOption.value,
                            })
                          }
                          placeholder='Select rating...'
                          isSearchable={false}
                          styles={{
                            control: (styles) => ({
                              ...styles,
                              textAlign: 'start',
                            }),
                          }}
                        />
                        <Form.Control
                          as='textarea'
                          rows={2}
                          name='overallImpressionComments'
                          value={formData.overallImpressionComments}
                          onChange={handleChange}
                          placeholder='Comments...'
                        />
                      </Form.Group>
                    </div>
                  </div>
                  <Button variant='primary' type='submit' className='mt-4 w-100'>
                    Submit Evaluation
                  </Button>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
