import React, { useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import Sidebar from "./Sidebar";
import DashNavbar from "./DashNavbar";

const Jobposting = (props) => {
  const [jobId, setJobId] = useState("");
  const [job_title, setJobTitle] = useState("");
  const [description, setDescription] = useState("");
  const [vacancies, setVacancies] = useState("");
  const [experience, setExperience] = useState("");
  const [jobLocation, setJobLocation] = useState("");
  const [roleType, setRoleType] = useState("");
  const [state, setState] = useState(""); // State field
  const [division, setDivision] = useState("");
  const [qualification, setQualification] = useState("");

  const roleOptions = [
    { value: "Full Time", label: "Full Time" },
    { value: "Part Time", label: "Part Time" },
    { value: "Contractual", label: "Contractual" },
    { value: "Intern", label: "Intern" },
  ];

  // Array of US states
  const stateOptions = [
    "Andhra Pradesh","Arunachal Pradesh","Assam","Bihar","Chhattisgarh","Goa","Gujarat","Haryana","Himachal Pradesh","Jharkhand","Karnataka","Kerala","Madhya Pradesh","Maharashtra","Manipur","Meghalaya","Mizoram","Nagaland","Odisha","Punjab","Rajasthan","Sikkim","Tamil Nadu","Telangana","Tripura","Uttar Pradesh","Uttarakhand","West Bengal"
  ];

  const [newJobCount, setNewJobCount] = useState(0);

  const handleRoleChange = (e) => {
    setRoleType(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    props.settingInfo(
      jobId,
      job_title,
      description,
      vacancies,
      experience,
      jobLocation,
      roleType,
      state,
      division,
      qualification
    );

    try {
      const response = await axios.post(
        "https://raasbackend.vetrinahealthcare.com/JobPosting_view/",
        {
          job_id: jobId,
          job_title,
          description,
          vacancies,
          experience,
          job_location: jobLocation,
          role_type: roleType,
          state,
          division,
          qualification,
        }
      );

      setNewJobCount(newJobCount + 1);

      await Swal.fire({
        title: "Job Posted!",
        text: "Your job has been successfully posted.",
        icon: "success",
        confirmButtonText: "OK",
        timer: 1000,
        timerProgressBar: true,
        showConfirmButton: false,
      });

      setJobId("");
      setJobTitle("");
      setDescription("");
      setVacancies("");
      setExperience("");
      setJobLocation("");
      setRoleType("");
      setState("");
      setDivision("");
      setQualification("");
    } catch (error) {
      console.error("Error posting job:", error);

      Swal.fire({
        title: "Error",
        text: "An error occurred while posting the job.",
        icon: "error",
        confirmButtonText: "OK",
        timer: 4000,
        timerProgressBar: true,
        showConfirmButton: false,
      });
    }
  };

  return (
    <div>
      <DashNavbar />
      <div className="wrapper">
        <Sidebar />
        <div className="main">
          <div className="row justify-content-center" style={{ width: "100%" }}>
            <div className="col-md-8 col-9 auth-inner mt-3 mb-5">
              <h2 className="display-6 gradient-text mb-4">Job Posting Form</h2>
              <form onSubmit={handleSubmit}>
                {/* Job ID and Job Title */}
                <div className="mb-3">
                  <div className="row justify-content-center align-items-center">
                    <div className="col-md-6">
                      <label className="form-label text-end pe-3">Job ID:</label>
                      <input
                        type="text"
                        className="form-control"
                        value={jobId}
                        onChange={(e) => setJobId(e.target.value)}
                        pattern="[0-9]*"
                        required
                      />
                    </div>
                    <div className="col-md-6">
                      <label className="form-label text-end pe-3">Job Title:</label>
                      <input
                        type="text"
                        className="form-control"
                        value={job_title}
                        onChange={(e) => setJobTitle(e.target.value)}
                        required
                      />
                    </div>
                  </div>
                </div>

                {/* Vacancies and Experience */}
                <div className="mb-3">
                  <div className="row justify-content-center align-items-center">
                    <div className="col-md-6">
                      <label className="form-label text-end pe-3">Vacancies:</label>
                      <input
                        type="text"
                        className="form-control"
                        value={vacancies}
                        onChange={(e) => {
                          const input = e.target.value;
                          if (/^\d*$/.test(input)) {
                            setVacancies(input);
                          }
                        }}
                        pattern="[0-9]*"
                        required
                      />
                    </div>

                    <div className="col-md-6">
                      <label className="form-label text-end pe-3">Qualification:</label>
                      <input
                        type="text"
                        className="form-control"
                        value={qualification}
                        onChange={(e) => setQualification(e.target.value)}
                        required
                      />
                    </div>
                  </div>
                </div>

                {/* Job Location and Role Type */}
                <div className="mb-3">
                  <div className="row justify-content-center align-items-center">
                    <div className="col-md-6">
                      <label className="form-label text-end pe-3">Experience:</label>
                      <input
                        type="text"
                        className="form-control"
                        value={experience}
                        onChange={(e) => setExperience(e.target.value)}
                        required
                      />
                    </div>
                    <div className="col-md-6">
                      <label className="form-label text-end pe-3">Role Type:</label>
                      <select
                        className="form-control"
                        value={roleType}
                        onChange={handleRoleChange}
                        required
                      >
                        <option value="" disabled>
                          Choose...
                        </option>
                        {roleOptions.map((option) => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>

                {/* State Dropdown */}
                <div className="mb-3">
                  <div className="row justify-content-center align-items-center">
                    <div className="col-md-6">
                      <label className="form-label text-end pe-3">State:</label>
                      <select
                        className="form-control"
                        value={state}
                        onChange={(e) => setState(e.target.value)}
                        required
                      >
                        <option value="" disabled>
                          Choose a state...
                        </option>
                        {stateOptions.map((state) => (
                          <option key={state} value={state}>
                            {state}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="col-md-6">
                      <label className="form-label text-end pe-3">Division:</label>
                      <input
                        type="text"
                        className="form-control"
                        value={division}
                        onChange={(e) => setDivision(e.target.value)}
                        required
                      />
                    </div>
                  </div>
                </div>

                {/* Job Location and Description */}
                <div className="mb-3">
                  <div className="row justify-content-center align-items-center">
                    <div className="col-md-6">
                      <label className="form-label text-end pe-3">Job Location:</label>
                      <input
                        type="text"
                        className="form-control"
                        value={jobLocation}
                        onChange={(e) => setJobLocation(e.target.value)}
                        required
                      />
                    </div>
                    <div className="col-md-6">
                      <label className="form-label">Description:</label>
                      <textarea
                        className="form-control"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        required
                      />
                    </div>
                  </div>
                </div>

                {/* Submit Button */}
                <div className="pt-3">
                  <div className="row justify-content-center">
                    <div className="col-md-6">
                      <button type="submit" className="btn btn-primary w-100">
                        Post
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Jobposting;
