import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import DashNavbar from './DashNavbar'
import Sidebar from './Sidebar'
import 'bootstrap/dist/css/bootstrap.min.css'
import { Table, Pagination } from 'react-bootstrap'
import Swal from 'sweetalert2'
import { FcApproval, FcHighPriority } from 'react-icons/fc'
import Select from 'react-select'
import { Modal, Button, Form } from 'react-bootstrap'

const OfferLetterGenerator = () => {
  const [letters, setLetters] = useState([])
  const [candidateNames, setCandidateNames] = useState([])
  const navigate = useNavigate()
  const [searchQuery, setSearchQuery] = useState('')
  const [filteredLetters, setFilteredLetters] = useState([])
  const [currentPage, setCurrentPage] = useState(1)

  const itemsPerPage = 5

  const [sentStatus, setSentStatus] = useState({})
  const handleCloseUploadModal = () => {
    setShowUploadModal(false)
    setShowError(false) // Clear validation messages
    setFormData({
      section: 'Offer Letter',
      candidateName: '',
      file: null,
    })
  }
  const handleOpenUploadModal = () => setShowUploadModal(true)

  useEffect(() => {
    async function fetchLetters() {
      try {
        const response = await axios.get('https://raasbackend.vetrinahealthcare.com/get_letters/')
        const data = response.data
        if (data && Array.isArray(data.letters)) {
          setLetters(data.letters)
          initializeSentStatus(data.letters)
        } else {
          console.error('Invalid data format: letters field is not an array')
          setLetters([])
        }
      } catch (error) {
        console.error('Error fetching letters:', error)
        setLetters([])
      }
    }
    async function fetchCandidateNames() {
      try {
        const response = await axios.get(
          'https://raasbackend.vetrinahealthcare.com/get_candidate_names/'
        )
        const data = response.data
        if (data && Array.isArray(data.candidate_names)) {
          setCandidateNames(data.candidate_names)
        } else {
          console.error(
            'Invalid data format: candidate_names field is not an array'
          )
          setCandidateNames([])
        }
      } catch (error) {
        console.error('Error fetching candidate names:', error)
        setCandidateNames([])
      }
    }
    fetchLetters()
    fetchCandidateNames()
  }, [])

  const initializeSentStatus = (lettersData) => {
    const initialSentStatus = {}
    lettersData.forEach((letter) => {
      initialSentStatus[letter.pk] = letter.fields.sent == 1
      console.log(letter.fields.sent)
    })
    setSentStatus(initialSentStatus)
    console.log(initialSentStatus)
  }
  const sendEmail = async (candidateName, pdfPath, LetterType, index) => {
    try {
      const response = await axios.post(
        'https://raasbackend.vetrinahealthcare.com/send_offer_letter_email/',
        {
          candidate_name: candidateName,
          pdf_path: pdfPath,
          letter_type: LetterType,
        }
      )
      Swal.fire({
        icon: 'success',
        title: 'Email Sent!',
        text: 'The email has been successfully sent.',
        timer: 1000,
        timerProgressBar: true,
        showConfirmButton: false,
      })

      // Disable the button and update its text to 'Sent'
      const updatedSentStatus = { ...sentStatus }
      updatedSentStatus[letters[index].pk] = true
      setSentStatus(updatedSentStatus)
      await axios.put(
        `https://raasbackend.vetrinahealthcare.com/update_sent_status/${letters[index].pk}/`,
        { sent: 1 }
      )
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Email Not Sent!',
        text: 'The email has been not  sent.',
        timer: 4000,
        timerProgressBar: true,
        showConfirmButton: false,
      })
    }
  }

  const downloadPdf = async (pdfPath) => {
    try {
      const response = await axios.get(
        `https://raasbackend.vetrinahealthcare.com/download_pdf/?pdfPath=${pdfPath}`,
        { responseType: 'blob' } // Ensure we get the Blob type
      )

      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'offer_letter.pdf') // Set the proper name for the file
      document.body.appendChild(link)
      link.click()
      // Cleanup
      document.body.removeChild(link)
      window.URL.revokeObjectURL(url)
    } catch (error) {
      console.error('Error downloading PDF:', error)
    }
  }

  const [formData, setFormData] = useState({
    candidateName: '',
    position: '',
    salary: '',
    date: '',
    section: 'Offer Letter',
  })
  const [showModal, setShowModal] = useState(false)
  const [showUploadModal, setShowUploadModal] = useState(false)

  const handleChange = (e) => {
    const { name, value, type, files } = e.target
    const formattedValue =
      name === 'date' ? new Date(value).toISOString().split('T')[0] : value
    setFormData((prevState) => ({
      ...prevState,
      [name]: type === 'file' ? files[0] : formattedValue,
    }))
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      await axios.post('https://raasbackend.vetrinahealthcare.com/offer_letter/', formData)

      if (formData.section === 'Offer Letter') {
        navigate('/OfferLetter', { state: formData })
        console.log('offer zali generate')
      } else if (formData.section === 'Rejection Letter') {
        navigate('/letterform', { state: formData })
        handleCloseModal()
        console.log('nahi zali offer')
      }
    } catch (error) {
      console.error('Error generating offer letter:', error)
    }
  }

  const handleOpenModal = () => {
    setShowModal(true)
  }

  const handleCloseModal = () => {
    setShowModal(false)
    setShowError(false) // Clear validation messages
    setFormData({
      section: 'Offer Letter',
      candidateName: '',
      position: '',
      salary: '',
      date: '',
      file: null,
    })
  }

  const formatDateTime = (dateTimeString) => {
    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: '2-digit',
      hour12: true,
    }
    return new Date(dateTimeString).toLocaleString(undefined, options)
  }
  useEffect(() => {
    const filteredData = letters.filter((letter) => {
      const candidateMatches = letter.fields.candidate_name
        .toLowerCase()
        .includes(searchQuery.toLowerCase())
      const typeMatches = letter.fields.LetterType.toLowerCase().includes(
        searchQuery.toLowerCase()
      )
      return candidateMatches || typeMatches
    })
    setFilteredLetters(filteredData)
  }, [letters, searchQuery])

  const indexOfLastItem = currentPage * itemsPerPage
  const indexOfFirstItem = indexOfLastItem - itemsPerPage
  const currentItems = filteredLetters.slice(indexOfFirstItem, indexOfLastItem)

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber)
  }

  const uploadhandleSubmit = async (e) => {
    e.preventDefault()
    const data = new FormData()
    data.append('section', formData.section)
    data.append('candidateName', formData.candidateName)
    data.append('file', formData.file) // Make sure the file is correctly appended

    try {
      const response = await axios.post('dtl/uploadtheofferletter', data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      console.log('Success:', response.data) // Success message
      Swal.fire({
        icon: 'success',
        title: 'Uploaded!',
        text: 'The offer letter has been successfully uploaded.',
        timer: 2000,
        timerProgressBar: true,
        showConfirmButton: false,
      })
      handleCloseUploadModal()
    } catch (error) {
      console.error('Error uploading offer letter:', error) // Error message
      Swal.fire({
        icon: 'error',
        title: 'Upload Failed!',
        text: 'Failed to upload the offer letter.',
        timer: 2000,
        timerProgressBar: true,
        showConfirmButton: false,
      })
    }
  }

  const [showError, setShowError] = useState(false)

  const validateForm = (e) => {
    e.preventDefault()

    // Validation logic based on the section selected (Offer Letter or Rejection Letter)
    let isValid = true

    if (!formData.candidateName) {
      setShowError(true)
      isValid = false
    }

    if (formData.section === 'Offer Letter') {
      if (!formData.position || !formData.salary || !formData.date) {
        setShowError(true)
        isValid = false
      }
    }

    if (formData.section === 'Rejection Letter') {
      if (!formData.date) {
        setShowError(true)
        isValid = false
      }
    }

    if (isValid) {
      setShowError(false)
      handleSubmit(e) // Proceed with form submission if valid
    }
  }

  return (
    <>
      <div
        style={{ display: 'flex', flexDirection: 'column', height: '100vh' }}
      >
        <DashNavbar />
        <div className='wrapper'>
          <Sidebar />
          <div className='main'>
            <div className='container mt-2 '>
              <div className='d-flex justify-content-center align-items-center letterpage'>
                <div className='auth-inner w-100'>
                  <div className='bg-white rounded '>
                    <h2 className='display-6 gradient-text mb-4'>Letters</h2>
                    <div className='d-flex justify-content-center '>
                      <div className='input-group search1 '>
                        <div className='form-outline w-100 '>
                          <input
                            type='search'
                            className='form-control'
                            placeholder='Search by Candidate and Letter type'
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                          />
                          <button
                            className='btn btn-primary searchicon'
                            type='button'
                          >
                            <i className='fas fa-search'></i>
                          </button>
                        </div>
                      </div>
                    </div>

                    <div
                      className='d-flex justify-content-start'
                      style={{
                        columnGap: '10px',
                      }}
                    >
                      {' '}
                      <Button
                        onClick={handleOpenModal}
                        className='btn btn-primary mb-3'
                      >
                        Create Letter +
                      </Button>
                      <Button
                        onClick={handleOpenUploadModal}
                        className='btn btn-primary mb-3'
                      >
                        Upload Offer Letter
                      </Button>
                      {/* <Button className='btn btn-primary mb-3'>Template</Button> */}
                    </div>
                    <Table hover responsive='lg' size='lg'>
                      <thead className='thead-dark'>
                        <tr>
                          <th>Sr.No</th>
                          <th>Candidate Name</th>
                          <th>Letter Type</th>
                          <th>Letter PDF</th>
                          <th>Time</th>
                          <th>Send Mail</th>
                          <th>Mail Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {currentItems.map((letter, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{letter.fields.candidate_name}</td>
                            <td>{letter.fields.LetterType}</td>
                            <td>
                              <Button
                                onClick={() =>
                                  downloadPdf(letter.fields.pdf_path)
                                }
                                disabled={
                                  letter.fields.LetterType ===
                                  'Rejection Letter'
                                }
                              >
                                Download
                              </Button>
                            </td>
                            <td>{formatDateTime(letter.fields.Time)}</td>
                            <td>
                              <Button
                                onClick={() =>
                                  sendEmail(
                                    letter.fields.candidate_name,
                                    letter.fields.pdf_path,
                                    letter.fields.LetterType,
                                    index
                                  )
                                }
                                disabled={sentStatus[letter.pk]}
                              >
                                {sentStatus[letter.pk] ? 'Sent' : 'Send'}
                              </Button>
                            </td>
                            <td>
                              {sentStatus[letter.pk] ? (
                                <div style={{ fontSize: '28px' }}>
                                  <FcApproval />{' '}
                                </div>
                              ) : (
                                <div style={{ fontSize: '28px' }}>
                                  <FcHighPriority />
                                </div>
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                  <Pagination>
                    <Pagination.Prev
                      onClick={() => handlePageChange(currentPage - 1)}
                      disabled={currentPage === 1}
                    />
                    {Array.from(
                      {
                        length: Math.ceil(
                          filteredLetters.length / itemsPerPage
                        ),
                      },
                      (_, index) => (
                        <Pagination.Item
                          key={index + 1}
                          active={index + 1 === currentPage}
                          onClick={() => handlePageChange(index + 1)}
                        >
                          {index + 1}
                        </Pagination.Item>
                      )
                    )}
                    <Pagination.Next
                      onClick={() => handlePageChange(currentPage + 1)}
                      disabled={
                        currentPage ===
                        Math.ceil(filteredLetters.length / itemsPerPage)
                      }
                    />
                  </Pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal
          show={showModal}
          onHide={handleCloseModal}
          contentLabel='Offer Letter Form'
          style={{
            overlay: {
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              zIndex: 1000,
            },
            content: {
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: '60%',
              maxWidth: '600px',
              maxHeight: '80%',
              padding: '20px',
              borderRadius: '10px',
              boxShadow: '0 0 10px rgba(0, 0, 0, 0.3)',
            },
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title className=' gradient-text'>
              Offer Letter Form
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form
              onSubmit={() => {
                validateForm()
                handleSubmit()
              }}
            >
              <div className='mb-3'>
                <label htmlFor='section' className='form-label'>
                  Select Section:
                </label>
                <select
                  id='section'
                  name='section'
                  className='form-select'
                  value={formData.section}
                  onChange={handleChange}
                >
                  <option value='Offer Letter'>Offer Letter</option>
                  <option value='Rejection Letter'>Rejection Letter</option>
                </select>
              </div>
              {formData.section === 'Offer Letter' && (
                <>
                  <div className='mb-3'>
                    <label htmlFor='candidateName' className='form-label'>
                      Candidate Name:
                    </label>
                    <Select
                      options={candidateNames.map((candidate) => ({
                        value: candidate,
                        label: candidate,
                      }))}
                      value={
                        formData.candidateName
                          ? {
                              value: formData.candidateName,
                              label: formData.candidateName,
                            }
                          : null
                      }
                      onChange={(selectedOption) =>
                        setFormData({
                          ...formData,
                          candidateName: selectedOption?.value || '',
                        })
                      }
                      placeholder='Select candidate'
                      isSearchable={true}
                      required
                    />
                    {!formData.candidateName && showError && (
                      <div className='text-danger'>
                        Candidate name is required
                      </div>
                    )}
                  </div>
                  <div className='mb-3'>
                    <label htmlFor='position' className='form-label'>
                      Position:
                    </label>
                    <input
                      type='text'
                      className='form-control'
                      id='position'
                      name='position'
                      value={formData.position}
                      onChange={handleChange}
                      required
                    />
                    {!formData.position && showError && (
                      <div className='text-danger'>
                        Candidate name is required
                      </div>
                    )}
                  </div>
                  <div className='mb-3'>
                    <label htmlFor='salary' className='form-label'>
                      Salary Per Annum:
                    </label>
                    <input
                      type='text'
                      className='form-control'
                      id='salary'
                      name='salary'
                      value={formData.salary}
                      onChange={handleChange}
                      required
                    />
                    {!formData.salary && showError && (
                      <div className='text-danger'>
                        Candidate name is required
                      </div>
                    )}
                  </div>
                  <div className='mb-3'>
                    <label htmlFor='date' className='form-label'>
                      Date:
                    </label>
                    <input
                      type='date'
                      className='form-control'
                      id='date'
                      name='date'
                      value={formData.date}
                      onChange={handleChange}
                      required
                    />
                    {!formData.date && showError && (
                      <div className='text-danger'>
                        Candidate name is required
                      </div>
                    )}
                  </div>
                </>
              )}

              {formData.section === 'Rejection Letter' && (
                <>
                  <div className='mb-3'>
                    <label htmlFor='candidateName' className='form-label'>
                      Candidate Name:
                    </label>
                    <Select
                      options={candidateNames.map((candidate) => ({
                        value: candidate,
                        label: candidate,
                      }))}
                      value={
                        formData.candidateName
                          ? {
                              value: formData.candidateName,
                              label: formData.candidateName,
                            }
                          : null
                      }
                      onChange={(selectedOption) =>
                        setFormData({
                          ...formData,
                          candidateName: selectedOption?.value || '',
                        })
                      }
                      placeholder='Select candidate'
                      isSearchable={true}
                      required
                    />
                  </div>
                  <div className='mb-3'>
                    <label htmlFor='date' className='form-label'>
                      Date:
                    </label>
                    <input
                      type='date'
                      className='form-control'
                      id='date'
                      name='date'
                      value={formData.date}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </>
              )}
              <Button
                type='submit'
                className='btn btn-primary'
                onClick={validateForm}
              >
                Generate
              </Button>
            </form>
          </Modal.Body>
        </Modal>
        <Modal show={showUploadModal} onHide={handleCloseUploadModal}>
          <Modal.Header closeButton>
            <Modal.Title>Upload Offer Letter</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={validateForm}>
              <div className='mb-3'>
                <label htmlFor='section' className='form-label'>
                  Select Section:
                </label>
                <select
                  id='section'
                  name='section'
                  className='form-select'
                  value={formData.section}
                  onChange={handleChange}
                >
                  <option value='Offer Letter'>Offer Letter</option>
                </select>
              </div>
              {formData.section === 'Offer Letter' && (
                <>
                  <div className='mb-3'>
                    <label htmlFor='candidateName' className='form-label'>
                      Candidate Name:
                    </label>
                    <Select
                      options={candidateNames.map((candidate) => ({
                        value: candidate,
                        label: candidate,
                      }))}
                      value={
                        formData.candidateName
                          ? {
                              value: formData.candidateName,
                              label: formData.candidateName,
                            }
                          : null
                      }
                      onChange={(selectedOption) =>
                        setFormData({
                          ...formData,
                          candidateName: selectedOption?.value || '',
                        })
                      }
                      placeholder='Select candidate'
                      isSearchable={true}
                      required
                    />
                    {!formData.candidateName && showError && (
                      <div className='text-danger'>
                        Candidate name is required
                      </div>
                    )}
                  </div>
                  <Form.Group className='mb-3'>
                    <Form.Label>Upload Offer Letter</Form.Label>
                    <Form.Control
                      type='file'
                      name='file'
                      accept='.pdf'
                      className='custom-input'
                      onChange={handleChange}
                      required
                    />
                    {!formData.file && showError && (
                      <div className='text-danger'>
                        Offer letter file is required
                      </div>
                    )}
                  </Form.Group>
                </>
              )}
            </form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant='secondary' onClick={handleCloseUploadModal}>
              Close
            </Button>
            <Button
              type='submit'
              className='btn btn-primary'
              onClick={validateForm}
            >
              Upload
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  )
}

export default OfferLetterGenerator
