// import React, { useState, useEffect } from 'react'
// import axios from 'axios'
// import 'bootstrap/dist/css/bootstrap.min.css'
// import '../css/dash.css'
// import { Table, Modal } from 'react-bootstrap'

// const ApplicantModal = ({
//   show,
//   onHide,
//   applicant,
//   jobapplications,
//   setSuccessMessage,
//   updateJobApplication,
// }) => {
//   const jobStatusOptions = [
//     { value: 'applied', label: 'Applied', bgColor: 'bg-info' },
//     { value: 'under_review', label: 'Under Review', bgColor: 'bg-secondary' },
//     {
//       value: 'interview_scheduled',
//       label: 'Interview Scheduled',
//       bgColor: 'bg-warning',
//     },
//     { value: 'selected', label: 'Selected', bgColor: 'bg-success' },
//     { value: 'rejected', label: 'Rejected', bgColor: 'bg-danger' },
//   ]

//   const [applicationList, setApplicationList] = useState([])

//   const handleStatusChange = (id, newStatus) => {
//     axios
//       .put(`https://raasbackend.vetrinahealthcare.com/applicants/update_status/${id}/`, {
//         job_status: newStatus,
//       })
//       .then((response) => {
//         setSuccessMessage('Job status updated successfully')
//         updateJobApplication(id, newStatus)
//         fetchApplications()
//       })
//       .catch((error) => {
//         console.error('Error updating job status:', error)
//       })
//   }

//   const fetchApplications = async () => {
//     try {
//       const response = await axios.get('https://raasbackend.vetrinahealthcare.com/applicants/')
//       const updatedApplications = await Promise.all(
//         response.data.map(async (application) => {
//           try {
//             const jobTitleResponse = await axios.get(
//               `https://raasbackend.vetrinahealthcare.com/applicant/job-title/${application.user}`
//             )
//             const jobData = jobTitleResponse.data[0] // Assuming the response is an array
//             return {
//               ...application,
//               job_title: jobData ? jobData.job_title : 'N/A',
//               similarity_score: jobData ? jobData.similarly_score : 'N/A',
//             }
//           } catch (error) {
//             console.error('Error processing application:', error)
//           }
//         })
//       )

//       setApplicationList(updatedApplications)
//     } catch (error) {
//       console.error('Error fetching applications:', error)
//     }
//   }

//   // const initializeChart = () => {
//   //   const ctx = document.getElementById('personalityChart').getContext('2d')
//   //   if (chartInstance) chartInstance.destroy() // Destroy any existing chart to avoid duplicates
//   //   const newChartInstance = new Chart(ctx, {
//   //     type: 'bar', // or 'line', 'pie', etc.
//   //     data: {
//   //       labels: [
//   //         'Openness',
//   //         'Conscientiousness',
//   //         'Extraversion',
//   //         'Agreeableness',
//   //         'Neuroticism',
//   //       ],
//   //       datasets: [
//   //         {
//   //           label: 'Personality Traits',
//   //           data: [60, 70, 80, 90, 50], // Example data
//   //           backgroundColor: [
//   //             '#4e73df',
//   //             '#1cc88a',
//   //             '#36b9cc',
//   //             '#f6c23e',
//   //             '#e74a3b',
//   //           ],
//   //         },
//   //       ],
//   //     },
//   //     options: {
//   //       responsive: true,
//   //       plugins: {
//   //         legend: { position: 'top' },
//   //       },
//   //     },
//   //   })
//   //   setChartInstance(newChartInstance)
//   // }

//   useEffect(() => {
//     if (show) {
//       fetchApplications()
//       //initializeChart()
//     }
//   }, [show])

//   return (
//     <Modal show={show} onHide={onHide} dialogClassName='modal-xl' centered>
//       <Modal.Header closeButton>
//         <Modal.Title className='gradient-text'>Applicant Details</Modal.Title>
//       </Modal.Header>
//       <Modal.Body>
//         <Table bordered>
//           <thead className='thead-dark'>
//             <tr>
//               <th scope='col'>Job Title</th>
//               <th scope='col'>Job Status</th>
//               <th scope='col'>Similarity Score</th>
//               <th scope='col'>Applied Date</th>
//             </tr>
//           </thead>
//           <tbody>
//             {applicationList.map((jobApplication, index) => (
//               <tr key={jobApplication.id}>
//                 <td className='text-center p-2'>{jobApplication.job_title}</td>
//                 <td className='text-center p-2'>
//                   <select
//                     className={`form-select ${
//                       jobStatusOptions.find(
//                         (option) => option.value === jobApplication.job_status
//                       )?.bgColor
//                     }`}
//                     value={jobApplication.job_status}
//                     onChange={(e) =>
//                       handleStatusChange(jobApplication.id, e.target.value)
//                     }
//                   >
//                     {jobStatusOptions.map((option) => (
//                       <option key={option.value} value={option.value}>
//                         {option.label}
//                       </option>
//                     ))}
//                   </select>
//                 </td>
//                 <td className='text-center p-2'>
//                   {jobApplication.similarity_score !== null
//                     ? jobApplication.similarity_score
//                     : 'N/A'}
//                 </td>
//                 <td className='text-center p-2'>
//                   {new Date(jobApplication.applied_date).toLocaleDateString()}
//                 </td>
//               </tr>
//             ))}
//           </tbody>
//         </Table>
//         <div className='row justify-content-center text-center'>
//           <h4 className='gradient-text'>Personality Assessment</h4>
//           <canvas id='personalityChart' width='400' height='160'></canvas>
//         </div>
//       </Modal.Body>
//     </Modal>
//   )
// }

// export default ApplicantModal


//updated by sufiyan

// import React, { useState, useEffect } from 'react'
// import axios from 'axios'
// import { Table, Modal } from 'react-bootstrap'
// import { Chart } from 'chart.js/auto'
// import 'bootstrap/dist/css/bootstrap.min.css'
// import '../css/dash.css'

// const ApplicantModal = ({
//   show,
//   onHide,
//   applicant,
//   jobapplications,
//   setSuccessMessage,
//   updateJobApplication,
// }) => {
//   const jobStatusOptions = [
//     { value: 'applied', label: 'Applied', bgColor: 'bg-info' },
//     { value: 'under_review', label: 'Under Review', bgColor: 'bg-secondary' },
//     {
//       value: 'interview_scheduled',
//       label: 'Interview Scheduled',
//       bgColor: 'bg-warning',
//     },
//     { value: 'selected', label: 'Selected', bgColor: 'bg-success' },
//     { value: 'rejected', label: 'Rejected', bgColor: 'bg-danger' },
//   ]

//   const [applicationList, setApplicationList] = useState([])
//   const [chartInstance, setChartInstance] = useState(null)

//   const handleStatusChange = (id, newStatus) => {
//     axios
//       .put(`https://raasbackend.vetrinahealthcare.com/applicants/update_status/${id}/`, {
//         job_status: newStatus,
//       })
//       .then((response) => {
//         setSuccessMessage('Job status updated successfully')
//         updateJobApplication(id, newStatus)
//         fetchApplications()
//       })
//       .catch((error) => {
//         console.error('Error updating job status:', error)
//       })
//   }

//   const fetchApplications = async () => {
//     try {
//       const response = await axios.get('https://raasbackend.vetrinahealthcare.com/applicants/')
//       const updatedApplications = await Promise.all(
//         response.data.map(async (application) => {
//           try {
//             const jobTitleResponse = await axios.get(
//               `https://raasbackend.vetrinahealthcare.com/applicant/job-title/${application.user}`
//             )
//             const jobData = jobTitleResponse.data[0] // Assuming the response is an array
//             return {
//               ...application,
//               job_title: jobData ? jobData.job_title : 'N/A',
//               similarity_score: jobData ? jobData.similarly_score : 'N/A',
//             }
//           } catch (error) {
//             console.error('Error processing application:', error)
//           }
//         })
//       )

//       setApplicationList(updatedApplications)
//     } catch (error) {
//       console.error('Error fetching applications:', error)
//     }
//   }

//   const initializeChart = () => {
//     const ctx = document.getElementById('personalityChart').getContext('2d')
//     // Destroy the existing chart instance if it exists
//     if (chartInstance) chartInstance.destroy()

//     // Create a new chart instance
//     const newChartInstance = new Chart(ctx, {
//       type: 'bar', // or 'line', 'pie', etc.
//       data: {
//         labels: [
//           'Openness',
//           'Conscientiousness',
//           'Extraversion',
//           'Agreeableness',
//           'Neuroticism',
//         ],
//         datasets: [
//           {
//             label: 'Personality Traits',
//             data: [60, 70, 80, 90, 50], // Example data
//             backgroundColor: [
//               '#4e73df',
//               '#1cc88a',
//               '#36b9cc',
//               '#f6c23e',
//               '#e74a3b',
//             ],
//           },
//         ],
//       },
//       options: {
//         responsive: true,
//         plugins: {
//           legend: { position: 'top' },
//         },
//       },
//     })
//     setChartInstance(newChartInstance)
//   }

//   useEffect(() => {
//     if (show) {
//       fetchApplications()
//       initializeChart()
//     }

//     // Cleanup function to destroy chart when modal closes or reopens
//     return () => {
//       if (chartInstance) {
//         chartInstance.destroy()
//         setChartInstance(null)
//       }
//     }
//   }, [show])

//   return (
//     <Modal show={show} onHide={onHide} dialogClassName='modal-xl' centered>
//       <Modal.Header closeButton>
//         <Modal.Title className='gradient-text'>Applicant Details</Modal.Title>
//       </Modal.Header>
//       <Modal.Body>
//         <Table bordered>
//           <thead className='thead-dark'>
//             <tr>
//               <th scope='col'>Job Title</th>
//               <th scope='col'>Job Status</th>
//               <th scope='col'>Similarity Score</th>
//               <th scope='col'>Applied Date</th>
//             </tr>
//           </thead>
//           <tbody>
//             {applicationList.map((jobApplication) => (
//               <tr key={jobApplication.id}>
//                 <td className='text-center p-2'>{jobApplication.job_title}</td>
//                 <td className='text-center p-2'>
//                   <select
//                     className={`form-select ${
//                       jobStatusOptions.find(
//                         (option) => option.value === jobApplication.job_status
//                       )?.bgColor
//                     }`}
//                     value={jobApplication.job_status}
//                     onChange={(e) =>
//                       handleStatusChange(jobApplication.id, e.target.value)
//                     }
//                   >
//                     {jobStatusOptions.map((option) => (
//                       <option key={option.value} value={option.value}>
//                         {option.label}
//                       </option>
//                     ))}
//                   </select>
//                 </td>
//                 <td className='text-center p-2'>
//                   {jobApplication.similarity_score !== null
//                     ? jobApplication.similarity_score
//                     : 'N/A'}
//                 </td>
//                 <td className='text-center p-2'>
//                   {new Date(jobApplication.applied_date).toLocaleDateString()}
//                 </td>
//               </tr>
//             ))}
//           </tbody>
//         </Table>
//         <div className='row justify-content-center text-center'>
//           <h4 className='gradient-text'>Personality Assessment</h4>
//           <canvas id='personalityChart' width='400' height='160'></canvas>
//         </div>
//       </Modal.Body>
//     </Modal>
//   )
// }

// export default ApplicantModal





import React, { useState, useEffect, useRef } from 'react'
import axios from 'axios'
import 'bootstrap/dist/css/bootstrap.min.css'
import '../css/dash.css'
import { Table, Modal } from 'react-bootstrap'
import Chart from 'chart.js/auto'

const ApplicantModal = ({
  show,
  onHide,
  applicant,
  jobapplications,
  setSuccessMessage,
  updateJobApplication,
}) => {
  const jobStatusOptions = [
    { value: 'applied', label: 'Applied', bgColor: 'bg-info' },
    { value: 'under_review', label: 'Under Review', bgColor: 'bg-secondary' },
    {
      value: 'interview_scheduled',
      label: 'Interview Scheduled',
      bgColor: 'bg-warning',
    },
    { value: 'selected', label: 'Selected', bgColor: 'bg-success' },
    { value: 'rejected', label: 'Rejected', bgColor: 'bg-danger' },
  ]

  const [applicationList, setApplicationList] = useState([])

  const handleStatusChange = (id, newStatus) => {
    axios
      .put(`https://raasbackend.vetrinahealthcare.com/applicants/update_status/${id}/`, {
        job_status: newStatus,
      })
      .then((response) => {
        setSuccessMessage('Job status updated successfully')
        updateJobApplication(id, newStatus)
        fetchApplications()
      })
      .catch((error) => {
        console.error('Error updating job status:', error)
      })
  }

  const fetchApplications = async () => {
    try {
      const response = await axios.get('https://raasbackend.vetrinahealthcare.com/applicants/')
      const updatedApplications = await Promise.all(
        response.data.map(async (application) => {
          try {
            const jobTitleResponse = await axios.get(
              `https://raasbackend.vetrinahealthcare.com/applicant/job-title/${application.user}`
            )
            const jobData = jobTitleResponse.data[0]
            return {
              ...application,
              job_title: jobData ? jobData.job_title : 'N/A',
              similarity_score: jobData ? jobData.similarly_score : 'N/A',
            }
          } catch (error) {
            console.error('Error processing application:', error)
          }
        })
      )

      setApplicationList(updatedApplications)
    } catch (error) {
      console.error('Error fetching applications:', error)
    }
  }

  useEffect(() => {
    if (show) {
      fetchApplications()
    }
  }, [show])

  return (
    <Modal show={show} onHide={onHide} dialogClassName='modal-xl' centered>
      <Modal.Header closeButton>
        <Modal.Title className='gradient-text'>Applicant Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Table bordered>
          <thead className='thead-dark'>
            <tr>
              <th scope='col'>Job Title</th>
              <th scope='col'>Job Status</th>
              <th scope='col'>Similarity Score</th>
              <th scope='col'>Applied Date</th>
            </tr>
          </thead>
          <tbody>
            {applicationList.map((jobApplication, index) => (
              <tr key={jobApplication.id}>
                <td className='text-center p-2'>{jobApplication.job_title}</td>
                <td className='text-center p-2'>
                  <select
                    className={`form-select ${
                      jobStatusOptions.find(
                        (option) => option.value === jobApplication.job_status
                      )?.bgColor
                    }`}
                    value={jobApplication.job_status}
                    onChange={(e) =>
                      handleStatusChange(jobApplication.id, e.target.value)
                    }
                  >
                    {jobStatusOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                </td>
                <td className='text-center p-2'>
                  {jobApplication.similarity_score !== null
                    ? jobApplication.similarity_score
                    : 'N/A'}
                </td>
                <td className='text-center p-2'>
                  {new Date(jobApplication.applied_date).toLocaleDateString()}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <div className='row justify-content-center text-center'>
          <h4 className='gradient-text'>Personality Assessment</h4>
          <canvas id='personalityChart' width='400' height='160'></canvas>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default ApplicantModal