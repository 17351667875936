import React, { useRef } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import { Container, Row, Col } from 'react-bootstrap'
import DashNavbar from './DashNavbar'
import Sidebar from './Sidebar'
import { useLocation } from 'react-router-dom'
import letterheader from '../../Assets/Vetrina-header.png'
import letterfooter from '../../Assets/Vetrina-footer.png'
import html2pdf from 'html2pdf.js'
import axios from 'axios'
import Swal from 'sweetalert2'
import { useNavigate } from 'react-router-dom'

const OfferLetter = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const formData = location.state || {}
  const formatDate = (date) => {
    if (date) {
      const d = new Date(date)
      const day = String(d.getDate()).padStart(2, '0')
      const month = String(d.getMonth() + 1).padStart(2, '0')
      const year = d.getFullYear()
      return `${day}/${month}/${year}`
    }
    return ''
  }

  const offerLetterRef = useRef(null)

  const downloadAsPDF = async () => {
    const opt = {
      margin: 0.5,
      filename: 'offer_letter.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
    }

    const pdfBlob = await html2pdf()
      .from(offerLetterRef.current)
      .set(opt)
      .output('blob')

    const formDataToSend = new FormData()
    formDataToSend.append(
      'pdf',
      pdfBlob,
      `${formData.candidateName}_offer_letter.pdf`
    )
    formDataToSend.append('candidateName', formData.candidateName)
    try {
      const response = await axios.post(
        'https://raasbackend.vetrinahealthcare.com/save_pdf/',
        formDataToSend,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      )
      console.log('PDF saved successfully:', response.data)
      Swal.fire({
        icon: 'success',
        title: 'Letter GeneratedSuccessfully',
        text: 'For Download Redirecting to another page...',
        timer: 1000,
        timerProgressBar: true,
        showConfirmButton: false,
      }).then(() => {
        navigate('/letterform')
      })
    } catch (error) {
      console.error('Error saving PDF:', error)
      Swal.fire({
        icon: 'error',
        title: 'Error Saving PDF',
        text: 'An error occurred while saving the PDF. Please try again later.',
        timer: 4000,
        timerProgressBar: true,
        showConfirmButton: false,
      })
    }
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
      <DashNavbar />
      <div style={{ display: 'flex', flex: 1 }}>
        <Sidebar />
        <div
          style={{
            backgroundColor: '#f0f0f0',
            padding: '20px',
            width: '100%',
            marginTop: '3em',
          }}
        >
          <Container
            className='bg-white p-4 overflow-auto'
            style={{ maxWidth: '595px', height: '842px' }} // Fix the height here
            ref={offerLetterRef}
          >
            <Row>
              <Col>
                <img
                  src={letterheader}
                  className='img-fluid mb-4'
                  style={{ marginTop: '-40px' }}
                />
                {/* <h2 className="text-center">Offer Letter </h2> */}
                <div className='text-end'>{formatDate(formData.date)}</div>
                <p className='text-start'>
                  To, <br /> Address : <br /> Pincode: <br /> Mobile No.:
                </p>
                <p className='text-start'>
                  Subject : Offer letter for the position of {formData.position}
                </p>
                <p className='text-start'>Dear {formData.candidateName},</p>
                <p className='text-start'>
                  Based on our discussion in your interview we would like to
                  offer you the position of
                  <strong>
                    {formData.position} at VETRINA HEALTHCARE PVT. LTD.
                  </strong>
                  You will report to {} who will define you roles and
                  responsibility.
                </p>
                <p className='text-start'>
                  Your annual CTC will be{' '}
                  <strong>INR {formData.salary}/- PER ANNUM.</strong>
                  We will increase your salary, after analyzing your
                  performance.
                </p>
                <p className='text-start'>
                  Initially you will be based at <strong>HQ .</strong>You will
                  be responsilble for the development of company business of
                  Vetrina Healthcare Pvt. Ltd. You are liable to be transferred
                  from time to time to other places as may be notify to you by
                  company.
                </p>
                <p>
                  The company might pay variable bonus and incentives. This will
                  be dependent on your perfomence in company in specific time
                  frame. The decision to grant a bonus and the manner in which
                  it will be calculated is at the sole discretion of the company
                  and is not negotiable.
                </p>
                <p>
                  You will be required to abide by the rules and regulations of
                  <strong>VETRINA HEALTHCARE PVT. LTD.</strong> ,that may be in
                  force at the time of your appointment and also that may be
                  promulgated from time to time thereafter.
                </p>{' '}
              </Col>
            </Row>
            <img
              src={letterfooter}
              className='img-fluid mt-4'
              style={{ marginBottom: '-42px' }}
            />
            <div className='page-break'></div>
            <Row>
              <Col>
                <img
                  src={letterheader}
                  className='img-fluid mb-4'
                  style={{ marginTop: '-40px' }}
                />
                <Container
                  className='bg-white p-4 overflow-auto'
                  style={{ maxWidth: '595px', height: '842px' }}
                >
                  <p>
                    If you have any questions or concerns, please feel free to
                    contact us.
                  </p>
                  <p className='text-start'>
                    Thanks & Regards
                    <br />
                    Ms. Abhilasha Gaikwad
                    <br />
                    HR Manager
                    <br />
                    VetVETRINA HEALTHCARE PVT. LTD.
                  </p>

                  <h2>Expense Structure</h2>

                  <p>
                    This page details the expense structure, including
                    allowances, bonuses, etc.
                  </p>
                  <ul>
                    <li>Travel: {formData.travelAllowance}</li>
                    <li>Accommodation: {formData.accommodationAllowance}</li>
                    <li>Other Perks: {formData.otherPerks}</li>
                  </ul>
                  <img
                    src={letterfooter}
                    className='img-fluid mt-4'
                    style={{ marginBottom: '-42px' }}
                  />
                </Container>
              </Col>
            </Row>
          </Container>
          <div
            style={{
              position: 'absolute',
              top: '79px',
              right: '20px',
            }}
          >
            <button onClick={downloadAsPDF}>Save PDF</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OfferLetter

// import React, { useRef } from 'react'
// import 'bootstrap/dist/css/bootstrap.min.css'
// import { Container, Row, Col } from 'react-bootstrap'
// import DashNavbar from './DashNavbar'
// import Sidebar from './Sidebar'
// import { useLocation } from 'react-router-dom'
// // import letterheader from '../../Assets/letterheader.png'
// // import letterfooter from '../../Assets/letterfooter.png'
// import header from '../../Assets/Vetrina-header.png'
// import footer from '../../Assets/Vetrina-footer.png'
// import html2pdf from 'html2pdf.js'
// import axios from 'axios'
// import Swal from 'sweetalert2'
// import { useNavigate } from 'react-router-dom'
// import '../css/OfferLetter.css'

// const OfferLetter = () => {
//   const location = useLocation()
//   const navigate = useNavigate()
//   const formData = location.state || {}
//   const formatDate = (date) => {
//     if (date) {
//       const d = new Date(date)
//       const day = String(d.getDate()).padStart(2, '0')
//       const month = String(d.getMonth() + 1).padStart(2, '0')
//       const year = d.getFullYear()
//       return `${day}/${month}/${year}`
//     }
//     return ''
//   }

//   const offerLetterRef = useRef(null)

//   const downloadAsPDF = async () => {
//     const opt = {
//       margin: 0.5,
//       filename: 'offer_letter.pdf',
//       image: { type: 'jpeg', quality: 0.98 },
//       html2canvas: { scale: 2, useCORS: true },
//       jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
//       pagebreak: { mode: ['css', 'legacy'] }, // Ensure that CSS page-break is respected
//     }

//     const pdfBlob = await html2pdf()
//       .from(offerLetterRef.current)
//       .set(opt)
//       .output('blob')

//     const formDataToSend = new FormData()
//     formDataToSend.append(
//       'pdf',
//       pdfBlob,
//       `${formData.candidateName}_offer_letter.pdf`
//     )
//     formDataToSend.append('candidateName', formData.candidateName)

//     try {
//       const response = await axios.post(
//         'https://raasbackend.vetrinahealthcare.com/save_pdf/',
//         formDataToSend,
//         {
//           headers: { 'Content-Type': 'multipart/form-data' },
//         }
//       )
//       Swal.fire({
//         icon: 'success',
//         title: 'Letter Generated Successfully',
//         text: 'For Download Redirecting to another page...',
//         timer: 1000,
//         timerProgressBar: true,
//         showConfirmButton: false,
//       }).then(() => {
//         navigate('/letterform')
//       })
//     } catch (error) {
//       Swal.fire({
//         icon: 'error',
//         title: 'Error Saving PDF',
//         text: 'An error occurred while saving the PDF. Please try again later.',
//         timer: 4000,
//         timerProgressBar: true,
//         showConfirmButton: false,
//       })
//     }
//   }

//   return (
//     <div style={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
//       <DashNavbar />
//       <div style={{ display: 'flex', flex: 1 }}>
//         <Sidebar />
//         <div
//           style={{
//             backgroundColor: '#f0f0f0',
//             padding: '20px',
//             width: '100%',
//             marginTop: '3em',
//           }}
//         >
//           <Container
//             className='bg-white p-4 overflow-auto'
//             style={{ maxWidth: '595px', height: '842px' }} // Fix the height here
//             ref={offerLetterRef}
//           >
//             <Row>
//               <Col>
//                 <img
//                   src={header}
//                   className='img-fluid mb-4'
//                   style={{ marginTop: '-40px' }}
//                 />
//                 {/* <h2 className="text-center">Offer Letter </h2> */}
//                 <div className='text-end'>{formatDate(formData.date)}</div>
//                 <p className='text-start'>
//                   To, <br /> Address : <br /> Pincode: <br /> Mobile No.:
//                 </p>
//                 <p className='text-start'>
//                   Subject : Offer letter for the position of {formData.position}
//                 </p>
//                 <p className='text-start'>Dear {formData.candidateName},</p>
//                 <p className='text-start'>
//                   Based on our discussion in your interview we would like to
//                   offer you the position of&nbsp;
//                   <strong>
//                     {formData.position} at VETRINA HEALTHCARE PVT. LTD.
//                   </strong>
//                   &nbsp;You will report to {} who will define you roles and
//                   responsibility.
//                 </p>
//                 <p className='text-start'>
//                   Your annual CTC will be{' '}
//                   <strong>INR {formData.salary}/- PER ANNUM.</strong>
//                   &nbsp;We will increase your salary, after analyzing your
//                   performance.
//                 </p>
//                 <p className='text-start'>
//                   Initially you will be based at <strong>HQ .</strong>You will
//                   be responsilble for the development of company business of
//                   Vetrina Healthcare Pvt. Ltd. You are liable to be transferred
//                   from time to time to other places as may be notify to you by
//                   company.
//                 </p>
//               </Col>
//             </Row>
//             <img
//               src={footer}
//               className='img-fluid mt-4'
//               style={{ marginBottom: '-42px' }}
//             />
//           </Container>
//           <div className='page-break'></div>
//           <Container
//             className='bg-white p-4 overflow-auto'
//             style={{ maxWidth: '595px', height: '842px' }}
//           >
//             <Row>
//               <Col>
//                 <img
//                   src={header}
//                   className='img-fluid mb-4'
//                   style={{ marginTop: '-40px' }}
//                 />
//                 <p>
//                   The company might pay variable bonus and incentives. This will
//                   be dependent on your perfomence in company in specific time
//                   frame. The decision to grant a bonus and the manner in which
//                   it will be calculated is at the sole discretion of the company
//                   and is not negotiable.
//                 </p>
//                 <p>
//                   You will be required to abide by the rules and regulations
//                   of&nbsp;
//                   <strong>VETRINA HEALTHCARE PVT. LTD.</strong> ,that may be in
//                   force at the time of your appointment and also that may be
//                   promulgated from time to time thereafter.
//                 </p>
//                 <p>
//                   If you have any questions or concerns, please feel free to
//                   contact us.
//                 </p>
//                 <p className='text-start'>
//                   Thanks & Regards
//                   <br />
//                   Ms. Abhilasha Gaikwad
//                   <br />
//                   HR Manager
//                   <br />
//                   VetVETRINA HEALTHCARE PVT. LTD.
//                 </p>
//                 <h2>Expense Structure</h2>
//                 <p>
//                   This page details the expense structure, including allowances,
//                   bonuses, etc.
//                 </p>
//                 <ul>
//                   <li>Travel: {formData.travelAllowance}</li>
//                   <li>Accommodation: {formData.accommodationAllowance}</li>
//                   <li>Other Perks: {formData.otherPerks}</li>
//                 </ul>
//                 <img
//                   src={footer}
//                   className='img-fluid mt-4'
//                   style={{ marginBottom: '-42px' }}
//                 />
//               </Col>
//             </Row>
//           </Container>
//           <div
//             style={{
//               position: 'absolute',
//               top: '79px',
//               right: '20px',
//             }}
//           >
//             <button onClick={downloadAsPDF}>Save PDF</button>
//           </div>
//         </div>
//       </div>
//     </div>
//   )
// }

// export default OfferLetter
